<template>
  <div>
    <index-banner />
    <router-view style="background:#fff;font-size: 14px;" />
    <index-footer />
  </div>
</template>

<script>
import $ from 'jquery'
import './../../../../Public/utils/new-index/js/move-top'
import './../../../../Public/utils/new-index/js/easing'
$(document).ready(function ($) {
  $('.scroll').click(function (event) {
    event.preventDefault()
    $('html,body').animate(
      {
        scrollTop: $(this.hash).offset().top
      },
      1000
    )
  })
})

$(document).ready(function () {
  $().UItoTop({
    easingType: 'easeOutQuart'
  })
})

import IndexFooter from './../com/indexfooter.vue'
import IndexBanner from './../com/indexbanner.vue'
export default {
  name: '',
  data: function () {
    return {}
  },
  computed:{
    isLoggedIn: function () {
      return this.$store.state.User.haslogin
    },
  },

  components: {
    IndexFooter,IndexBanner
  },
  
}
</script>

<style lang="less">
@import url('./../../../../Public/resources/css/templates/home/index/newindex.min.css');
@import url('./../../../../Public/utils/new-index/css/bootstrap.css');
@import url('./../../../../Public/utils/new-index/css/font-awesome.css');
@import url('./../../../../Public/utils/new-index/css/style.css');

</style>
