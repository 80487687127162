<template>
  <div class="agileits-banner about-w3lsbnr"  style="font-size: 14px;">
    <div class="bnr-agileinfo">
      <!-- navigation-->
      <div class="top-nav w3-agiletop">
        <div class="container">
          <div class="navbar-header w3llogo">
            <h1 style="font-size: 1.8em; transform: translateY(15px);">
              <a @click="jumpPage('/')">智辉山河教育</a>
            </h1>
          </div>
          <div
            class="navbar-collapse"
          >
            <div class="w3menu navbar-right">
              <ul class="nav navbar">
                <li>
                  <a  @click="jumpPage('/')">首页</a>
                </li>
                <li>
                  <a class="active">产品特色</a>
                </li>
                <!-- <li>
                  <a @click="jumpPage('HomeIndexNewProducts')">产品列表</a>
                </li>
                <li>
                  <a @click="jumpPage('HomeIndexNewPrices')">产品价格</a>
                </li>
                <li>
                  <a @click="jumpPage('HomeIndexNewCooperation')">合作伙伴</a>
                </li> -->
              </ul>
            </div>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeIndexFooter',
  data: function () {
    return {}
  },
  methods: {
    jumpPage: function (dest) {
      dest === '/'
        ? this.$router.replace({ path: dest })
        : this.$router.push({path: dest})
    }
  }
}
</script>

<style lang="less" scoped>
@import url('./../../../../Public/resources/css/templates/home/index/newindex.min.css');
@import url('./../../../../Public/utils/new-index/css/bootstrap.css');
@import url('./../../../../Public/utils/new-index/css/font-awesome.css');
@import url('./../../../../Public/utils/new-index/css/style.css');
@import url('./../../../../Public/utils/element-ui/index.css');
@media (max-width: 480px) {
  .navbar li {
    text-align: right;
  }
}
</style>
