<template>
  <div class="footer-agile">
    <div class="container">
      <!-- <div class="footer-top-agileinfo">
        本产品由灯塔教育专利智能专业匹配算法驱动（专利号：201810642977.1）
      </div> -->
      <div class="footer-btm-agileinfo">
        <div class="col-md-6 col-xs-6 footer-grid footer-review">
          <h3>联系我们</h3>
          <div class="copy-w3lsright">
            <p>四川智辉山河教育科技有限责任公司</p>
            <!-- <p>028-67873357-转分机号2</p> -->
            <!-- <p>蜀ICP备16031008号-1</p> -->
          </div>
        </div>
        <div class="col-md-6 col-xs-6 footer-grid">
          <h3>公司简介</h3>
          <p>
            四川智辉山河教育科技有限责任公司致力于中小学相关教育产品和生涯产品开发，是四川省知名的教育科技公司。公司以四川大学国家双创示范基地为依托平台，其核心团队由四川大学高校博导教授，985大学一流开发团队，国家高级心理咨询师，知名生涯规划师等专家组成。
          </p>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
    <div style="display: flex; justify-content: center; padding-top: 20px">
      <a style="color: #fff" href="#" target="_blank">蜀ICP备16031008号-1</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeIndexFooter',
  data: function () {
    return {}
  }
}
</script>

<style lang="less" scoped>
@import url('./../../../../Public/utils/element-ui/index.css');
</style>
